import {
	Box,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	Radio,
	RadioGroup,
	Switch,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import NumberInput from "../../../components/NumberInput";
import { useEditSocietyConfigMutation, useGetSocietyConfigQuery } from "../../../redux/reducers/settings.reducer";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import { isEqual } from "lodash";

enum TAT_DIRECTION_E {
	INSIDE_PREMISES = 0,
	OUTSIDE_PREMISES = 1
}

interface ITATConfig {
	enabled: boolean;
	direction: TAT_DIRECTION_E;
	min_threshold_seconds: string;
	max_threshold_days: string;
}

const AdvancedSettings: FC = () => {
	const dispatch = useAppDispatch();

	const [tatConfigInputs, setTatConfigInputs] = useState<ITATConfig>({
		enabled: true,
		direction: TAT_DIRECTION_E.INSIDE_PREMISES,
		min_threshold_seconds: "30",
		max_threshold_days: "7"
	});

	// APIS
	// GET SOCIETY CONFIG
	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery(null);

	// EDIT SOCIETY CONFIG
	const [
		editSocietyConfig,
		{
			isLoading: editSocietyConfigLoading,
			error: editSocietyConfigError,
			isSuccess: editSocietyConfigSuccess,
			reset: resetEditSocietyConfig
		}
	] = useEditSocietyConfigMutation();

	const societyConfig = useMemo(
		() =>
			getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
				? getSocietyConfigResponse.results[0]
				: null,
		[getSocietyConfigResponse]
	);

	const initialTatConfig = useMemo<ITATConfig>(() => {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			const tatConfig = societyConfig.tat_config[0].tat;

			return {
				enabled: tatConfig.enabled,
				direction: tatConfig.direction,
				min_threshold_seconds: String(tatConfig.min_tat_duration_limit),
				max_threshold_days: String(tatConfig.max_tat_duration_limit)
			};
		}

		return {
			enabled: true,
			direction: TAT_DIRECTION_E.INSIDE_PREMISES,
			min_threshold_seconds: "30",
			max_threshold_days: "7"
		};
	}, [societyConfig]);

	function handleChangeTATEnabled(checked: boolean) {
		setTatConfigInputs((currentInputs) => ({
			...currentInputs,
			enabled: checked
		}));
	}

	function handleChangeSelectedDirection(value: TAT_DIRECTION_E) {
		setTatConfigInputs((currentInputs) => ({
			...currentInputs,
			direction: value
		}));
	}

	function handleChangeTATThreshold(value: string, key: "min_threshold_seconds" | "max_threshold_days") {
		setTatConfigInputs((currentInputs) => ({
			...currentInputs,
			[key]: value
		}));
	}

	function handleSaveButtonClick() {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			editSocietyConfig({
				id: societyConfig.id,
				data: {
					tat_config: [
						{
							...societyConfig.tat_config[0],
							tat: {
								enabled: tatConfigInputs.enabled,
								direction: tatConfigInputs.direction,
								min_tat_duration_limit: Number(tatConfigInputs.min_threshold_seconds),
								max_tat_duration_limit: Number(tatConfigInputs.max_threshold_days)
							}
						}
					]
				}
			});
		}
	}

	useEffect(() => {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			const tatConfig = societyConfig.tat_config[0].tat;
			setTatConfigInputs({
				enabled: tatConfig.enabled,
				direction: tatConfig.direction,
				min_threshold_seconds: String(tatConfig.min_tat_duration_limit),
				max_threshold_days: String(tatConfig.max_tat_duration_limit)
			});
		}
	}, [societyConfig]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Failed to save turn around time changes",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	useEffect(() => {
		if (editSocietyConfigSuccess) {
			dispatch(
				showFeedbackNotification({
					message: "Turn around time changes saved successfully",
					severity: "success"
				})
			);

			resetEditSocietyConfig();
			refetchSocietyConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigSuccess]);

	return (
		<Box className="communication-channels-screen-wrapper">
			<Box className="overstay-alerts-title-wrapper">
				<Typography variant="h6" color="var(--color-primary-main)">
					Turn Around Time
				</Typography>

				<Switch checked={tatConfigInputs.enabled} onChange={(_event, checked) => handleChangeTATEnabled(checked)} />
			</Box>

			<Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
				<Box>
					<InputLabel>Calculate TAT for</InputLabel>

					<FormControl>
						<RadioGroup
							row
							value={tatConfigInputs.direction}
							onChange={(_event, value) => handleChangeSelectedDirection(Number(value) as TAT_DIRECTION_E)}
							sx={{ gap: "5rem" }}
						>
							<FormControlLabel
								value={TAT_DIRECTION_E.INSIDE_PREMISES}
								control={<Radio />}
								label="Inside Premises"
								disabled={!tatConfigInputs.enabled}
							/>

							<FormControlLabel
								value={TAT_DIRECTION_E.OUTSIDE_PREMISES}
								control={<Radio />}
								label="Outside Premises"
								disabled={!tatConfigInputs.enabled}
							/>
						</RadioGroup>
					</FormControl>
				</Box>

				<Box>
					<InputLabel htmlFor="min-threshold-input" disabled={!tatConfigInputs.enabled}>
						TAT Minimum Threshold
					</InputLabel>

					<NumberInput
						fullWidth
						size="small"
						id="min-threshold-input"
						sx={{ marginTop: "0.5rem", maxWidth: "300px" }}
						value={tatConfigInputs.min_threshold_seconds}
						onChange={(event) => handleChangeTATThreshold(event.target.value, "min_threshold_seconds")}
						InputProps={{
							endAdornment: <InputAdornment position="end">seconds</InputAdornment>
						}}
						disabled={!tatConfigInputs.enabled}
					/>
				</Box>

				<Box>
					<InputLabel htmlFor="max-threshold-input" disabled={!tatConfigInputs.enabled}>
						TAT Maximum Threshold
					</InputLabel>

					<NumberInput
						fullWidth
						size="small"
						id="max-threshold-input"
						sx={{ marginTop: "0.5rem", maxWidth: "300px" }}
						value={tatConfigInputs.max_threshold_days}
						onChange={(event) => handleChangeTATThreshold(event.target.value, "max_threshold_days")}
						InputProps={{
							endAdornment: <InputAdornment position="end">days</InputAdornment>
						}}
						disabled={!tatConfigInputs.enabled}
					/>
				</Box>
			</Box>

			<Box>
				<LoadingButton
					disableElevation
					variant="contained"
					color="success"
					startIcon={<SaveIcon />}
					onClick={handleSaveButtonClick}
					loading={editSocietyConfigLoading}
					disabled={isEqual(initialTatConfig, tatConfigInputs)}
				>
					Save Changes
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default AdvancedSettings;
