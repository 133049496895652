import { getAPIHeaderOptions, transformErrorResponse } from "../../utils/apiUtils";
import {
	IAddVehicleRequestData,
	IBlacklistedVehicleDetails,
	ICreateVehicleOwnerVehiclesRequestData,
	IEditVehicleOwnerVehiclesRequestData,
	IGetAuthorizedListCountsResponseData,
	IGetBlacklistedVehicleCountsResponseData,
	IGetBlacklistedVehiclesListRequestData,
	IGetBlacklistedVehiclesListResponseData,
	IGetVehicleOwnersListRequestData,
	IGetVehicleOwnersListResponseData,
	IUploadAuthorizedVehiclesExcelRequestData,
	IUploadAuthorizedVehiclesExcelResponseData,
	IUploadBlacklistVehiclesExcelRequestData,
	IUploadBlacklistVehiclesExcelResponseData
} from "../../types";
import { queriesApi } from "./queries.reducer";

const dataManagement = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET AUTHORIZED VEHICLE OWNERS LIST
		getVehicleOwnersList: builder.query<IGetVehicleOwnersListResponseData, IGetVehicleOwnersListRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle-owner-vehicles",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// GET BLACKLISTED VEHICLES LIST
		getBlacklistedVehiclesList: builder.query<
			IGetBlacklistedVehiclesListResponseData,
			IGetBlacklistedVehiclesListRequestData
		>({
			query: (requestData) => ({
				url: "/analytics/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// ADD VEHICLE
		addVehicle: builder.mutation<IBlacklistedVehicleDetails, IAddVehicleRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// ADD VEHICLE OWNER
		addVehicleOwner: builder.mutation<unknown, ICreateVehicleOwnerVehiclesRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle-owner-vehicles",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT VEHICLE OWNER
		editVehicleOwner: builder.mutation<unknown, IEditVehicleOwnerVehiclesRequestData>({
			query: (requestData) => ({
				url: `/analytics/vehicle-owner-vehicles/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET AUTHORIZED VEHICLE COUNTS
		getAuthorizedVehicleCounts: builder.query<IGetAuthorizedListCountsResponseData, void>({
			query: () => ({
				url: "/analytics/authorized_vehicle_metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET BLACKLIST VEHICLE COUNTS
		getBlacklistedVehicleCounts: builder.query<IGetBlacklistedVehicleCountsResponseData, void>({
			query: () => ({
				url: "/analytics/blacklist_vehicle_metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// UPLOAD AUTHORIZED VEHICLES EXCEL
		uploadAuthorizedVehiclesData: builder.mutation<
			IUploadAuthorizedVehiclesExcelResponseData,
			IUploadAuthorizedVehiclesExcelRequestData
		>({
			query: (requestData) => ({
				url: "/analytics/upload_authorized_vehicles",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: (response) => {
				if (response && Array.isArray(response.data)) {
					response.data = {
						data: response.data,
						message: "Failed to upload data"
					};
				}

				return response;
			}
		}),

		// DELETE BLACKLIST VEHICLES
		deleteBlacklistVehicles: builder.mutation<void, number[]>({
			query: (vehicleIdsList) => ({
				url: `/analytics/vehicle/${vehicleIdsList.join(",")}`,
				headers: getAPIHeaderOptions(true),
				method: "DELETE"
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// UPLOAD BLACKLIST VEHICLES EXCEL
		uploadBlacklistVehiclesData: builder.mutation<
			IUploadBlacklistVehiclesExcelResponseData,
			IUploadBlacklistVehiclesExcelRequestData
		>({
			query: (requestData) => ({
				url: "/analytics/upload_blacklisted_vehicles",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			})
		}),

		// DELETE VEHICLE OWNER VEHICLE
		deleteVehicleOwnerVehicle: builder.mutation<unknown, number[]>({
			query: (ids) => ({
				url: "/analytics/vehicle-owner-vehicles/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "DELETE",
				params: { id: ids.join(",") }
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// DELETE VEHICLE OWNERS
		deleteVehicleOwners: builder.mutation<unknown, number[]>({
			query: (ownerIds) => ({
				url: `/analytics/vehicle-owner-vehicles/${ownerIds.join(",")}`,
				headers: getAPIHeaderOptions(true),
				method: "DELETE"
			}),
			transformErrorResponse: transformErrorResponse
		})
	})
});

export const {
	// GET AUTHORIZED VEHICLE OWNERS LIST
	useGetVehicleOwnersListQuery,
	// GET BLACKLISTED VEHICLES LIST
	useGetBlacklistedVehiclesListQuery,
	// ADD VEHICLE
	useAddVehicleMutation,
	// GET AUTHORIZED VEHICLE COUNTS
	useGetAuthorizedVehicleCountsQuery,
	// GET BLACKLIST VEHICLE COUNTS
	useGetBlacklistedVehicleCountsQuery,
	// UPLOAD AUTHORIZED VEHICLES EXCEL
	useUploadAuthorizedVehiclesDataMutation,
	// DELETE BLACKLIST VEHICLES
	useDeleteBlacklistVehiclesMutation,
	// UPLOAD BLACKLIST VEHICLES EXCEL
	useUploadBlacklistVehiclesDataMutation,
	// ADD VEHICLE OWNER
	useAddVehicleOwnerMutation,
	// EDIT VEHICLE OWNER
	useEditVehicleOwnerMutation,
	// DELETE VEHICLE OWNER VEHICLE
	useDeleteVehicleOwnerVehicleMutation,
	// DELETE VEHICLE OWNERS
	useDeleteVehicleOwnersMutation
} = dataManagement;
