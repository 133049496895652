import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { IBuildingDetails } from "../../../types";
import { BUILDING_TYPES_E } from "../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import AddFlatDialog from "./AddFlatDialog";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import NumberInput from "../../../components/NumberInput";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { convertToTitleCase } from "../../../utils/commonUtils";
import { isEqual } from "lodash";

interface IBuildingConfigurationComponentProps {
	buildingsList: IBuildingDetails[];
	initialBuildingsList: IBuildingDetails[];
	saveButtonLoading?: boolean;
	onDeleteBuilding: (index: number) => void;
	onSaveBuilding: (index: number, updatedBuildingDetails: IBuildingDetails) => void;
	onAddBuilding: () => number;
}

interface ISelectedBuildingInputs {
	name: string;
	delimiter: string;
	floors: string;
	flats: string;
	entries: string[];
	showFloorNumber: boolean;
}

const BuildingConfigurationComponent: FC<IBuildingConfigurationComponentProps> = (props) => {
	const { buildingsList, initialBuildingsList, saveButtonLoading, onDeleteBuilding, onSaveBuilding, onAddBuilding } =
		props;

	const dispatch = useAppDispatch();

	const buildingsListRef = useRef<HTMLDivElement>(null);

	const [selectedBuildingIndex, setSelectedBuildingIndex] = useState<number>(0);
	const [selectedEntriesIndex, setSelectedEntriesIndex] = useState<number[]>([]);
	const [flatSelectionEnabled, setFlatSelectionEnabled] = useState<boolean>(false);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);
	const [showAddFlatDialog, setShowAddFlatDialog] = useState<boolean>(false);
	const [selectedBuildingInputs, setSelectedBuildingInputs] = useState<ISelectedBuildingInputs>({
		name: "",
		delimiter: "",
		floors: "",
		flats: "",
		entries: [],
		showFloorNumber: true
	});

	const selectedBuildingInitialInputs = useMemo<ISelectedBuildingInputs>(() => {
		const initialBuildingDetails = initialBuildingsList[selectedBuildingIndex];

		if (initialBuildingDetails) {
			return {
				name: initialBuildingDetails.name,
				delimiter: initialBuildingDetails.delimiter,
				floors: initialBuildingDetails.number_of_floors,
				flats: initialBuildingDetails.flats_per_floor,
				entries: initialBuildingDetails.entries,
				showFloorNumber: initialBuildingDetails.show_floor_number
			};
		}

		return {
			name: "",
			delimiter: "",
			floors: "",
			flats: "",
			entries: [],
			showFloorNumber: true
		};
	}, [initialBuildingsList, selectedBuildingIndex]);

	const IsSaveButtonDisabled = useMemo<boolean>(() => {
		const selectedBuildingDetails = buildingsList[selectedBuildingIndex];

		if (!selectedBuildingDetails) return true;
		if (flatSelectionEnabled) return true;
		if (!selectedBuildingInputs.name || !selectedBuildingInputs.flats) return true;

		if (selectedBuildingInputs.showFloorNumber && !selectedBuildingInputs.floors) return true;

		if (selectedBuildingDetails.new_building && selectedBuildingInputs.name === "New Building") return true;

		if (isEqual(selectedBuildingInputs, selectedBuildingInitialInputs)) return true;

		return false;
	}, [
		buildingsList,
		flatSelectionEnabled,
		selectedBuildingIndex,
		selectedBuildingInitialInputs,
		selectedBuildingInputs
	]);

	const [buildingInputErrors, setBuildingInputErrors] = useState({
		name: "",
		delimiter: "",
		floors: "",
		flats: ""
	});

	function handleOpenAddFlatDialog() {
		setShowAddFlatDialog(true);
	}

	function handleCloseAddFlatDialog() {
		setShowAddFlatDialog(false);
	}

	function handleOpenDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(true);
	}

	function handleCloseDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(false);
	}

	function handleSubmitDeleteBuilding() {
		onDeleteBuilding(selectedBuildingIndex);
		setSelectedBuildingIndex(0);
		handleCloseDeleteConfirmationDialog();
	}

	function handleScrollBuildingList(scrollTo: "left" | "right") {
		if (!buildingsListRef.current) return;

		const scrollValue = scrollTo === "left" ? -300 : scrollTo === "right" ? 300 : 0;

		buildingsListRef.current.scrollTo({ left: scrollValue, behavior: "smooth" });
	}

	function handleChangeSelectedBuilding(index: number) {
		setSelectedBuildingIndex(index);
		setSelectedEntriesIndex([]);
	}

	function handleAddButtonClick() {
		setSelectedBuildingIndex(onAddBuilding());
	}

	function handleEnableFlatSelection() {
		setFlatSelectionEnabled(true);
	}

	function handleDisableFlatSelection() {
		setFlatSelectionEnabled(false);
		setSelectedEntriesIndex([]);
	}

	function getTrailingZeros(digits: string): number {
		const match = digits.match(/^0+/);
		return match ? match[0].length : 0;
	}

	function generateEntriesArray(
		floors: string,
		flats: string,
		buildingName: string,
		delimiter: string,
		showFloorNumber?: boolean
	): string[] {
		const numberOfFloors = floors && Number(floors) >= 0 ? Number(floors) : 0;
		const numberOfFlats = flats && Number(flats) >= 0 ? Number(flats) : 0;

		const floorsLength = floors.startsWith("0") ? floors.length : 1;

		const flatTrailingZeros = getTrailingZeros(flats);
		const flatNumberLength = flatTrailingZeros > 0 ? flatTrailingZeros + 1 : 0;

		const floorsArray = [...Array(numberOfFloors)].map((_, floorNumber) => {
			const floorPrefix = [...Array(floorsLength)].fill(0).join("");

			if (floorsLength >= 2) return `${floorPrefix}${floorNumber + 1}`.slice(floorsLength * -1);

			return String(floorNumber + 1);
		});

		const flatsArray = [...Array(numberOfFlats)].map((_, flatNumber) => {
			const flatPrefix = [...Array(flatTrailingZeros)].fill(0).join("");
			return `${flatPrefix}${flatNumber + 1}`.slice(flatNumberLength * -1);
		});

		if (showFloorNumber) {
			return floorsArray
				.map((floorNumber) => flatsArray.map((flatNumber) => `${buildingName}${delimiter}${floorNumber}${flatNumber}`))
				.flat();
		}

		return flatsArray.map((flatNumber) => `${buildingName}${delimiter}${flatNumber}`);
	}

	function handleChangeSelectedBuildingInput(
		value: string,
		key: keyof Omit<ISelectedBuildingInputs, "entries" | "showFloorNumber">
	) {
		const updatedBuildingInputValues = { ...selectedBuildingInputs };

		updatedBuildingInputValues[key] = value;

		updatedBuildingInputValues.entries = generateEntriesArray(
			updatedBuildingInputValues.floors,
			updatedBuildingInputValues.flats,
			convertToTitleCase(updatedBuildingInputValues.name),
			updatedBuildingInputValues.delimiter,
			updatedBuildingInputValues.showFloorNumber
		);

		setSelectedBuildingInputs(updatedBuildingInputValues);

		setBuildingInputErrors((currentBuildingInputErrors) => ({
			...currentBuildingInputErrors,
			[key]: ""
		}));
	}

	function handleChangeShowFlatNumberInput(isChecked: boolean) {
		const updatedBuildingInputValues = { ...selectedBuildingInputs };

		updatedBuildingInputValues.showFloorNumber = isChecked;

		updatedBuildingInputValues.entries = generateEntriesArray(
			updatedBuildingInputValues.floors,
			updatedBuildingInputValues.flats,
			convertToTitleCase(updatedBuildingInputValues.name),
			updatedBuildingInputValues.delimiter,
			updatedBuildingInputValues.showFloorNumber
		);

		setSelectedBuildingInputs(updatedBuildingInputValues);

		setBuildingInputErrors((currentBuildingInputErrors) => ({
			...currentBuildingInputErrors,
			showFloorNumber: ""
		}));
	}

	function handleToggleEntrySelection(index: number) {
		setSelectedEntriesIndex((currentSelectedEntriesIndex) => {
			if (currentSelectedEntriesIndex.includes(index)) {
				return currentSelectedEntriesIndex.filter((item) => item !== index);
			}

			const updatedSelectedEntriesIndex = [...currentSelectedEntriesIndex];
			updatedSelectedEntriesIndex.push(index);
			return updatedSelectedEntriesIndex;
		});
	}

	function handleSaveButtonClick() {
		const updatedBuildingInputErrors = {
			name: "",
			floors: "",
			flats: "",
			delimiter: ""
		};

		if (!selectedBuildingInputs.name) {
			updatedBuildingInputErrors.name = "Building name is required";
		}

		if (selectedBuildingInputs.showFloorNumber && !selectedBuildingInputs.floors) {
			updatedBuildingInputErrors.floors = "Floors is required";
		}

		if (!selectedBuildingInputs.flats) {
			updatedBuildingInputErrors.flats = "Units per floor is required";
		}

		if (!selectedBuildingInputs.delimiter) {
			updatedBuildingInputErrors.delimiter = "Delimiter is required";
		}

		setBuildingInputErrors(updatedBuildingInputErrors);

		if (
			updatedBuildingInputErrors.name ||
			updatedBuildingInputErrors.floors ||
			updatedBuildingInputErrors.flats ||
			updatedBuildingInputErrors.delimiter
		) {
			return;
		}

		onSaveBuilding(selectedBuildingIndex, {
			name: convertToTitleCase(selectedBuildingInputs.name),
			building_type: BUILDING_TYPES_E.BUILDING,
			entries: selectedBuildingInputs.entries,
			number_of_floors: selectedBuildingInputs.floors,
			flats_per_floor: selectedBuildingInputs.flats,
			new_building: false,
			delimiter: selectedBuildingInputs.delimiter,
			show_floor_number: selectedBuildingInputs.showFloorNumber
		});
	}

	function handleRemoveButtonClick() {
		setSelectedBuildingInputs((currentSelectedBuildingInputs) => ({
			...currentSelectedBuildingInputs,
			entries: currentSelectedBuildingInputs.entries.filter((_, index) => !selectedEntriesIndex.includes(index))
		}));

		setSelectedEntriesIndex([]);
		handleDisableFlatSelection();
	}

	function handleAddFlatSubmit(flatNumber: string) {
		const updatedBuildingInputs = { ...selectedBuildingInputs };

		if (updatedBuildingInputs.entries.includes(flatNumber)) {
			dispatch(
				showFeedbackNotification({
					message: "The entered unit number already exists in the building",
					severity: "warning"
				})
			);
			return;
		}

		updatedBuildingInputs.entries.push(flatNumber);
		setSelectedBuildingInputs(updatedBuildingInputs);

		handleCloseAddFlatDialog();
	}

	useEffect(() => {
		if (buildingsList.length > 0 && selectedBuildingIndex >= 0) {
			const buildingDetails = buildingsList[selectedBuildingIndex];

			setSelectedBuildingInputs({
				name: buildingDetails.name,
				floors: buildingDetails.number_of_floors,
				flats: buildingDetails.flats_per_floor,
				entries: buildingDetails.entries,
				delimiter: buildingDetails.delimiter,
				showFloorNumber: buildingDetails.show_floor_number
			});
		}
	}, [buildingsList, selectedBuildingIndex]);

	return (
		<Box className="building-configuration-component-wrapper">
			<Box className="buildings-list-wrapper">
				<Box className="buildings-list-scroll-arrow left" onClick={() => handleScrollBuildingList("left")}>
					<ArrowLeftIcon />
				</Box>

				<Box className="buildings-list" ref={buildingsListRef}>
					<Box
						sx={{
							overflowX: "auto",
							msOverflowStyle: "none",
							scrollbarWidth: "none",
							display: "flex",
							flexWrap: "nowrap",
							gap: "0.75rem"
						}}
					>
						{buildingsList.map((buildingItem, index) => (
							<Box className="buildings-list-item" key={index}>
								<Button
									disableElevation
									size="small"
									variant="contained"
									color="primary"
									startIcon={<ApartmentIcon />}
									sx={{ textTransform: "none" }}
									classes={{ containedPrimary: selectedBuildingIndex !== index ? "inactive" : "" }}
									onClick={() => handleChangeSelectedBuilding(index)}
								>
									{buildingItem.name}
								</Button>
							</Box>
						))}
					</Box>

					<Box className="buildings-list-item">
						<Button
							size="small"
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							onClick={handleAddButtonClick}
							sx={{ textTransform: "capitalize" }}
							disabled={buildingsList.some((item) => item.name === "New Building" || item.new_building)}
						>
							Add New Wing
						</Button>
					</Box>
				</Box>

				<Box className="buildings-list-scroll-arrow right" onClick={() => handleScrollBuildingList("right")}>
					<ArrowRightIcon />
				</Box>
			</Box>

			<Box className="building-inputs-wrapper">
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="wing-name-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Wing Name
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							variant="filled"
							id="wing-name-input"
							placeholder="Add wing name"
							value={selectedBuildingInputs.name}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "name")}
							error={!!buildingInputErrors.name}
							helperText={buildingInputErrors.name}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
							inputProps={{ style: { textTransform: "capitalize" } }}
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="delimiter-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Delimiter
						</InputLabel>

						<FormControl
							fullWidth
							size="small"
							variant="filled"
							error={!!buildingInputErrors.delimiter}
							hiddenLabel
							id="delimiter-input"
						>
							<Select
								fullWidth
								placeholder="Add delimiter"
								value={selectedBuildingInputs.delimiter}
								onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "delimiter")}
								disableUnderline
								classes={{ root: "building-input-root" }}
							>
								<MenuItem value="-">-</MenuItem>
								<MenuItem value="/">/</MenuItem>
							</Select>

							{buildingInputErrors.delimiter ? <FormHelperText>{buildingInputErrors.delimiter}</FormHelperText> : null}
						</FormControl>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="floors-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Floors
						</InputLabel>

						<NumberInput
							fullWidth
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							variant="filled"
							id="floors-input"
							placeholder="Add number of floors in building"
							value={selectedBuildingInputs.floors}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "floors")}
							error={!!buildingInputErrors.floors}
							helperText={buildingInputErrors.floors}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
							disabled={!selectedBuildingInputs.showFloorNumber}
						/>

						<FormControlLabel
							control={
								<Checkbox
									checked={selectedBuildingInputs.showFloorNumber}
									onChange={(_, checked) => handleChangeShowFlatNumberInput(checked)}
								/>
							}
							label="Show Floor Number"
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="flats-per-floor-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							{selectedBuildingInputs.showFloorNumber ? "Units Per Floor" : "Total Number of Units"}
						</InputLabel>

						<NumberInput
							fullWidth
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							variant="filled"
							id="flats-per-floor-input"
							placeholder="Add number of units per floor"
							value={selectedBuildingInputs.flats}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "flats")}
							error={!!buildingInputErrors.flats}
							helperText={buildingInputErrors.flats}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
						/>
					</Grid>
				</Grid>
			</Box>

			<Typography variant="h6" color="var(--color-primary-dark)">
				Preview of Wing
			</Typography>

			<Box className="building-config-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					onClick={handleOpenAddFlatDialog}
					disabled={flatSelectionEnabled}
				>
					Add Unit
				</Button>

				{flatSelectionEnabled ? (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CloseIcon />}
						onClick={handleDisableFlatSelection}
						sx={{ minWidth: "100px" }}
					>
						Cancel
					</Button>
				) : (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CheckBoxOutlineBlankIcon />}
						onClick={handleEnableFlatSelection}
						sx={{ minWidth: "100px" }}
					>
						Select
					</Button>
				)}

				{flatSelectionEnabled ? (
					<Button
						variant="outlined"
						color="error"
						size="small"
						startIcon={<DeleteOutlineIcon />}
						disabled={selectedEntriesIndex.length <= 0}
						onClick={handleRemoveButtonClick}
					>
						Remove
					</Button>
				) : null}
			</Box>

			<Box className="buildings-flats-preview-wrapper">
				<Box className="buildings-flats-preview">
					{selectedBuildingInputs.entries.map((item, index) => (
						<Box className="building-flats-preview-item" key={index}>
							{flatSelectionEnabled ? (
								<Box className="icon-wrapper" onClick={() => handleToggleEntrySelection(index)}>
									{selectedEntriesIndex.includes(index) ? (
										<CheckBoxIcon fontSize="inherit" />
									) : (
										<CheckBoxOutlineBlankIcon fontSize="inherit" />
									)}
								</Box>
							) : null}

							<Typography color="inherit" variant="body2">
								{item}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>

			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
				{buildingsList.length > 1 ? (
					<Button
						variant="outlined"
						color="error"
						startIcon={<DeleteOutlineIcon />}
						onClick={handleOpenDeleteConfirmationDialog}
					>
						Delete Wing
					</Button>
				) : null}

				<LoadingButton
					disableElevation
					variant="contained"
					color="success"
					startIcon={<SaveIcon />}
					sx={{ paddingX: 3 }}
					onClick={handleSaveButtonClick}
					disabled={IsSaveButtonDisabled}
					loading={saveButtonLoading}
					loadingPosition="start"
				>
					Save
				</LoadingButton>
			</Box>

			<ConfirmationDialog
				open={showDeleteConfirmationDialog}
				title="Delete Building"
				heading="Are you sure you want to delete this building?"
				onClose={handleCloseDeleteConfirmationDialog}
				onConfirm={handleSubmitDeleteBuilding}
				width="sm"
				color="error"
			/>

			<AddFlatDialog open={showAddFlatDialog} onClose={handleCloseAddFlatDialog} onSubmit={handleAddFlatSubmit} />
		</Box>
	);
};

export default BuildingConfigurationComponent;
