import axios from "axios";
import { BASE_API_URL, getAPIHeaderOptions } from "../../utils/apiUtils";
import { downloadFile } from "../../utils/commonUtils";
import {
	IDownloadEventLogsRequestData,
	IDownloadSocietyReportRequestData,
	IGetUptimeMetricsRequestData
} from "../../types";

const DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER = "filename=";

const handleDownloadSampleFile = (fileUrl: string): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}${fileUrl}`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob"
			})
			.then((response) => {
				const responseContent = String(response.headers["content-disposition"]);
				const fileName = responseContent.split(DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER)[1] ?? "";

				if (response.data) {
					downloadFile(new Blob([response.data]), fileName);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail));
	});
};

const handleDownloadAuthorizedList = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/download_authorized_excel`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob"
			})
			.then((response) => {
				if (response.data) {
					const responseContent = String(response.headers["content-disposition"]);
					const fileName = responseContent.split(DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER)[1] ?? "";

					downloadFile(new Blob([response.data]), fileName);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const handleDownloadBlacklist = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/download_vehicles_excel`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob",
				params: { auth_type: "blacklist" }
			})
			.then((response) => {
				if (response.data) {
					const responseContent = String(response.headers["content-disposition"]);
					const fileName = responseContent.split(DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER)[1] ?? "";

					downloadFile(new Blob([response.data]), fileName);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const handleDownloadEventLogs = (requestData: IDownloadEventLogsRequestData): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/download_events`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob",
				params: requestData
			})
			.then((response) => {
				if (response.data) {
					const responseContent = response.headers["content-disposition"];
					const fileName = responseContent.split(DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER)[1] ?? "";

					downloadFile(new Blob([response.data]), fileName);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const handleDownloadUptimeLogs = (requestData: IGetUptimeMetricsRequestData): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/devices/download-uptime-logs`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob",
				params: {
					start_time: requestData.start_time,
					end_time: requestData.end_time,
					device: requestData.device_id
				}
			})
			.then((response) => {
				if (response.data) {
					const responseContent = response.headers["content-disposition"];
					const fileName = responseContent.split(DOWNLOAD_RESPONSE_CONTENT_FILENAME_IDENTIFIER)[1] ?? "";

					downloadFile(new Blob([response.data]), fileName);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const handleDownloadSocietyReport = (requestData: IDownloadSocietyReportRequestData): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/society_reports_list/download`, {
				headers: getAPIHeaderOptions(true),
				responseType: "blob",
				params: requestData
			})
			.then((response) => {
				if (response.data) {
					downloadFile(new Blob([response.data]), requestData.file_name);
					resolve();
				} else {
					reject("Something went wrong");
				}
			})
			.catch((error) => reject(error?.response?.data?.detail ?? "Something went wrong"));
	});
};

const downloadServices = {
	handleDownloadSampleFile,
	handleDownloadAuthorizedList,
	handleDownloadBlacklist,
	handleDownloadEventLogs,
	handleDownloadUptimeLogs,
	handleDownloadSocietyReport
};

export default downloadServices;
