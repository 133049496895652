import CryptoJS from "crypto-js";
import { store } from "../redux";
import { pick } from "lodash";
import { convertSnakeToTitleCase } from "./commonUtils";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type NestedObjectType = {
	[key in string]: NestedObjectType | any;
};

type FlattenedObjectType = {
	[key in string]: any;
};

// export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? "";
export const BASE_API_URL = `${window.location.protocol}//${window.location.hostname}/api/v1`;

// API AUTH TOKEN
const API_REQUEST_HEADER_OPTIONS = {
	// "X-Cue": "34db55e07f7b39df480284397f7f42ec",
	// "X-Salt": "683239",
	// "X-Alpha": "21"
};

function getAuthToken() {
	const encryptionKey = String(process.env.REACT_APP_ENCRYPTION_KEY);
	const encryptedToken: string = store.getState().auth.user.token;

	const token = CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8);
	return token ? `token ${token}` : "";
}

export const getAPIHeaderOptions = (isAuthRequired = false) => {
	const token = getAuthToken();

	if (isAuthRequired && token) {
		return {
			...API_REQUEST_HEADER_OPTIONS,
			Authorization: token
		};
	}

	return API_REQUEST_HEADER_OPTIONS;
};

function flattenObject(object: NestedObjectType, parentKey?: string): FlattenedObjectType {
	let result: FlattenedObjectType = {};

	for (const [key, value] of Object.entries(object)) {
		if (typeof value === "object" && !Array.isArray(value)) {
			const flattenedValue = flattenObject(value, parentKey ? `${parentKey}.${key}` : key);

			result = {
				...result,
				...flattenedValue
			};
		} else {
			if (parentKey) {
				result[`${parentKey}.${key}`] = value;
			} else {
				result[key] = value;
			}
		}
	}

	return result;
}

function extractErrorMessage(error: FlattenedObjectType, parentKey?: string): string {
	if ("detail" in error) error = pick(error, ["detail"]);
	else if ("details" in error) error = pick(error, ["details"]);

	const errorKey = Object.keys(error)[0];
	const errorValue = Object.values(error)[0] ?? "Something went wrong";

	if (typeof errorValue === "object") {
		return extractErrorMessage(errorValue, errorKey);
	}

	const returnValue = String(errorValue);

	if (returnValue.toLowerCase().includes("this field")) {
		const replaceValue = parentKey ? parentKey : errorKey ? errorKey : "This field";
		return returnValue.replace(/this field/i, convertSnakeToTitleCase(replaceValue));
	} else if (returnValue.toLowerCase().includes("this value")) {
		const replaceValue = parentKey ? parentKey : errorKey ? errorKey : "This value";
		return returnValue.replace(/this value/i, convertSnakeToTitleCase(replaceValue));
	}

	return returnValue;
}

export const transformErrorResponse = (response: FetchBaseQueryError): FetchBaseQueryError => {
	if (response.data) {
		const error = flattenObject(response.data);
		response.data = extractErrorMessage(error);
	} else {
		response.data = "Something went wrong";
	}

	return response;
};
