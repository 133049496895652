import { Alert, Snackbar } from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../redux";
import { hideFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";

const FeedbackNotification: FC = () => {
	const dispatch = useAppDispatch();

	const feedbackNotificationState = useAppSelector((state) => state.feedbackNotification);

	function handleCloseFeedbackNotification() {
		dispatch(hideFeedbackNotification());
	}

	return (
		<Snackbar
			open={feedbackNotificationState.show}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			// TransitionComponent={TransitionLeft}
			autoHideDuration={3000}
			onClose={handleCloseFeedbackNotification}
		>
			<Alert
				onClose={handleCloseFeedbackNotification}
				variant="filled"
				severity={feedbackNotificationState.severity}
				sx={{ width: "100%" }}
				classes={{
					filledError: "notification-error",
					filledWarning: "notification-warning",
					filledSuccess: "notification-success"
				}}
			>
				{feedbackNotificationState.message}
			</Alert>
		</Snackbar>
	);
};

export default FeedbackNotification;
