import { Box, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridPagination, GridPaginationModel, GridRowSelectionModel } from "@mui/x-data-grid";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CancelIcon from "@mui/icons-material/Cancel";

import "./blacklist.css";
import { ROWS_PER_PAGE, VEHICLE_TYPES_LIST, VEHICLE_TYPE_E } from "../../../utils/constants";
import AddBlacklistVehicleDialog from "./AddBlacklistVehicleDialog";
import {
	CombinedRowType,
	IBlacklistedVehicleDetails,
	IGetBlacklistedVehiclesListRequestData,
	IUploadBlacklistVehicleExcelDetails,
	IUploadBlacklistVehiclesExcelResponseData,
	IUploadResponse,
	IUploadResponseBlacklistDetails,
	IUploadResponseData
} from "../../../types";
import {
	useDeleteBlacklistVehiclesMutation,
	useGetBlacklistedVehicleCountsQuery,
	useGetBlacklistedVehiclesListQuery,
	useUploadBlacklistVehiclesDataMutation
} from "../../../redux/reducers/dataManagement.reducer";
import TablePagination from "../../../components/TablePagination";
import downloadServices from "../../../redux/services/download.services";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import NewUploadDataDialog from "../../../components/NewUploadDataDialog";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import SecureLoginAccessButton from "../../../components/SecureLoginAccessButton";
import { openSecureLoginDialog } from "../../../redux/reducers/secureLoginDialog.reducer";

interface IBlacklistVehicleDetails {
	id: number;
	sr_no: number;
	vehicle_number: string;
	vehicle_type: number;
	reason: string;
}

const BlacklistPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const Blacklist: FC = () => {
	const dispatch = useAppDispatch();

	const searchInputRef = useRef<HTMLInputElement>(null);

	const [searchInput, setSearchInput] = useState<string>("");
	const [showAddBlacklistVehicleDialog, setShowAddBlacklistVehicleDialog] = useState<boolean>(false);
	const [showUploadDataDialog, setShowUploadDataDialog] = useState<boolean>(false);
	const [showDownloadButtonLoader, setShowDownloadButtonLoader] = useState<boolean>(false);
	const [rowSelectionEnabled, setRowSelectionEnabled] = useState<boolean>(false);
	const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);
	const [uploadDataLoading, setUploadDataLoading] = useState<boolean>(false);

	const [filters, setFilters] = useState<IGetBlacklistedVehiclesListRequestData>({
		auth_type: "blacklist",
		page: 1
	});

	// TABLE RENDERER STATES
	const [vehicleTypeDropdownAnchorElement, setVehicleTypeDropdownAnchorElement] = useState<HTMLElement | null>(null);
	const openVehicleTypeDropdown = Boolean(vehicleTypeDropdownAnchorElement);

	// APIS
	const {
		data: getBlacklistedVehiclesResponse,
		isLoading: getBlacklistedVehiclesLoading,
		isFetching: getBlacklistedVehiclesFetching,
		refetch: refetchBlacklistedVehicles
	} = useGetBlacklistedVehiclesListQuery(filters);

	const { data: blacklistedVehicleCounts, refetch: refetchBlacklistedVehicleCounts } =
		useGetBlacklistedVehicleCountsQuery();

	const [
		deleteBlacklistVehicles,
		{
			isLoading: deleteBlacklistVehiclesLoading,
			isSuccess: deleteBlacklistVehiclesSuccess,
			error: deleteBlacklistVehiclesError,
			reset: resetDeleteBlacklistVehiclesState
		}
	] = useDeleteBlacklistVehiclesMutation();

	const [uploadBlacklistVehiclesData] = useUploadBlacklistVehiclesDataMutation();

	const refetchAllData = useCallback(() => {
		refetchBlacklistedVehicles();
		refetchBlacklistedVehicleCounts();
	}, [refetchBlacklistedVehicleCounts, refetchBlacklistedVehicles]);

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleClearSearchInput() {
		setSearchInput("");
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };
			delete updatedFilters.number_plate;
			updatedFilters.page = 1;
			return updatedFilters;
		});

		if (searchInputRef.current) searchInputRef.current.focus();
	}

	function handleOpenAddBlacklistVehicleDialog() {
		setShowAddBlacklistVehicleDialog(true);
	}

	function handleCloseAddBlacklistVehicleDialog() {
		setShowAddBlacklistVehicleDialog(false);
		refetchAllData();
	}

	function handleOpenUploadDataDialog() {
		setShowUploadDataDialog(true);
	}

	function handleCloseUploadDataDialog() {
		setShowUploadDataDialog(false);
		refetchAllData();
	}

	function handleUploadBlacklistDetails(
		rowsData: CombinedRowType[],
		_customFlatSelectionEnabled?: boolean,
		_devices?: number[],
		isVerified?: boolean | undefined
	): Promise<IUploadResponse> {
		return new Promise((resolve) => {
			setUploadDataLoading(true);

			const uploadData: IUploadBlacklistVehicleExcelDetails[] = [];

			for (const rowDetails of rowsData) {
				if (rowDetails.is_deleted) continue;

				const vehicleTypeDetails = VEHICLE_TYPES_LIST.find(
					(vehicleTypeItem) => vehicleTypeItem.name === rowDetails["Vehicle Type"]
				);

				const uploadDataItem: IUploadBlacklistVehicleExcelDetails = {
					number_plate: rowDetails["Number Plate"],
					vehicle_type: vehicleTypeDetails?.id ?? VEHICLE_TYPE_E.FOUR_WHEELER,
					auth_type: "blacklist",
					comment: rowDetails.Comment
				};

				uploadData.push(uploadDataItem);
			}

			if (uploadData.length <= 0) {
				resolve({
					data: [],
					message: ""
				});
				return;
			}

			uploadBlacklistVehiclesData({
				data: uploadData,
				is_verified: isVerified ?? false
			})
				.unwrap()
				.then((response) => {
					resolve({
						data: [],
						message: response.message
					});

					dispatch(
						showFeedbackNotification({
							message: response.message,
							severity: "success"
						})
					);
				})
				.catch((error) => {
					if (!error.data) {
						resolve({
							data: [],
							message: "Something went wrong"
						});
						return;
					}

					const errorData = error.data as IUploadBlacklistVehiclesExcelResponseData;

					dispatch(
						showFeedbackNotification({
							message: errorData.message,
							severity: "error"
						})
					);

					const filteredErrorData = errorData.data.filter(
						(item) => !!item.database_data && Object.keys(item.database_data).length > 0
					);

					const returnData: IUploadResponseBlacklistDetails[] = [];

					for (const errorDataItem of filteredErrorData) {
						if (!errorDataItem.remarks) continue;

						if (errorDataItem.remarks === "Vehicle already present") {
							const vehicleTypeDetails = VEHICLE_TYPES_LIST.find(
								(vehicleTypeItem) => vehicleTypeItem.id === errorDataItem.database_data.vehicle?.vehicle_type || -1
							);

							returnData.push({
								remarks: errorDataItem.remarks,
								"Number Plate": errorDataItem.database_data.vehicle?.number_plate ?? "",
								"Vehicle Type": vehicleTypeDetails?.name ?? "",
								Comment: errorDataItem.database_data.vehicle?.comment ?? "",
								id: 0,
								sr_no: 0,
								is_deleted: false,
								errorFields:
									errorDataItem.database_data.vehicle && errorDataItem.database_data.vehicle.auth_type === "whitelist"
										? {
												"Number Plate": ["This number plate already exists in whitelist"]
										  }
										: {
												"Number Plate": ["Vehicle already present"]
										  }
							});
						}
					}

					resolve({
						data: returnData as IUploadResponseData[],
						message: errorData.message
					});
				})
				.finally(() => {
					setUploadDataLoading(false);
				});
		});
	}

	function handleSearchBlacklistVehicle(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (searchInput) updatedFilters.number_plate = searchInput;
			else delete updatedFilters.number_plate;

			updatedFilters.page = 1;

			return updatedFilters;
		});
	}

	function handleDownloadButtonClick() {
		setShowDownloadButtonLoader(true);

		downloadServices
			.handleDownloadBlacklist()
			.catch((error) => console.error("DOWNLOAD BLACKLIST FAILED ::", error))
			.finally(() => setShowDownloadButtonLoader(false));
	}

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			page: event.page + 1
		}));
	}

	function handleEnableRowSelection() {
		setRowSelectionEnabled(true);
	}

	function handleDisableRowSelection() {
		setRowSelectionEnabled(false);
		setSelectedRowIds([]);
	}

	function handleSelectRows(updatedValues: GridRowSelectionModel) {
		setSelectedRowIds(updatedValues as number[]);
	}

	function handleOpenDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(true);
	}

	function handleCloseDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(false);
	}

	function handleDeleteSelectedVehicles() {
		deleteBlacklistVehicles(selectedRowIds);
	}

	function handleOpenSecureLoginDialog() {
		dispatch(openSecureLoginDialog());
	}

	useEffect(() => {
		if (deleteBlacklistVehiclesSuccess) {
			resetDeleteBlacklistVehiclesState();
			handleCloseDeleteConfirmationDialog();
			handleDisableRowSelection();
			refetchAllData();
		}
	}, [deleteBlacklistVehiclesSuccess, refetchAllData, resetDeleteBlacklistVehiclesState]);

	useEffect(() => {
		if (deleteBlacklistVehiclesError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in deleteBlacklistVehiclesError && deleteBlacklistVehiclesError.data
							? String(deleteBlacklistVehiclesError)
							: "Failed to delete blacklisted vehicles",
					severity: "error"
				})
			);

			resetDeleteBlacklistVehiclesState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteBlacklistVehiclesError]);

	// TABLE RENDERER FUNCTIONS
	function handleOpenVehicleTypeDropdown(event: MouseEvent<HTMLDivElement>) {
		setVehicleTypeDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseVehicleTypeDropdown() {
		setVehicleTypeDropdownAnchorElement(null);
	}

	function handleChangeSelectedVehicleType(updatedValue: number) {
		handleCloseVehicleTypeDropdown();
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (updatedValue >= 0) updatedFilters.vehicle_type = updatedValue;
			else delete updatedFilters.vehicle_type;

			updatedFilters.page = 1;

			return updatedFilters;
		});
	}

	function renderVehicleTypeDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="vehicle-type-dropdown"
					aria-controls={openVehicleTypeDropdown ? "vehicle-type-dropdown-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={openVehicleTypeDropdown ? "true" : undefined}
					onClick={handleOpenVehicleTypeDropdown}
				>
					<Typography variant="subtitle2" textTransform="capitalize">
						{typeof filters.vehicle_type === "number"
							? filters.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER
								? "Four Wheeler"
								: "Two Wheeler"
							: "Vehicle Type"}
					</Typography>
					{openVehicleTypeDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="vehicle-type-dropdown-menu"
					anchorEl={vehicleTypeDropdownAnchorElement}
					open={openVehicleTypeDropdown}
					onClose={handleCloseVehicleTypeDropdown}
					MenuListProps={{ "aria-labelledby": "vehicle-type-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedVehicleType(-1)} sx={{ minWidth: 130 }}>
						<em>All</em>
					</MenuItem>

					{VEHICLE_TYPES_LIST.map((vehicleTypeItem) => (
						<MenuItem key={vehicleTypeItem.id} onClick={() => handleChangeSelectedVehicleType(vehicleTypeItem.id)}>
							{vehicleTypeItem.name}
						</MenuItem>
					))}
				</Menu>
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr. No.", width: 100, sortable: false },
		{ field: "vehicle_number", headerName: "Vehicle Number", flex: 1, minWidth: 150, sortable: false },
		{
			field: "vehicle_type",
			headerName: "Vehicle Type",
			flex: 1,
			minWidth: 150,
			sortable: false,
			renderHeader: renderVehicleTypeDropdown,
			valueGetter: (params) => (params.value === 0 ? "Four Wheeler" : "Two Wheeler")
		},
		{
			field: "reason",
			headerName: "Reason",
			flex: 2,
			minWidth: 200,
			sortable: false
		}
	];

	function getBlacklistRowDetails(blacklist: IBlacklistedVehicleDetails[]): IBlacklistVehicleDetails[] {
		const activePage = filters.page - 1;

		return blacklist.map((blacklistVehicleItem, index) => ({
			id: blacklistVehicleItem.id,
			sr_no: activePage * ROWS_PER_PAGE + index + 1,
			vehicle_number: blacklistVehicleItem.number_plate,
			vehicle_type: blacklistVehicleItem.vehicle_type,
			reason: blacklistVehicleItem.comment ?? ""
		}));
	}

	return (
		<Box className="blacklist-screen-wrapper">
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<Box className="blacklist-data-card">
						<Box className="blacklist-data-card-icon">
							<DirectionsCarFilledIcon color="error" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="blacklist-data-card-details">
							<Typography variant="body2" color="#00000080">
								Blacklisted Vehicles
							</Typography>

							<Typography fontWeight={600}>{blacklistedVehicleCounts?.black_listed_vehicles ?? 0}</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={3}>
					<Box className="blacklist-data-card">
						<Box className="blacklist-data-card-icon">
							<PeopleAltIcon color="error" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="blacklist-data-card-details">
							<Typography variant="body2" color="#00000080">
								Blacklisted People
							</Typography>

							<Typography fontWeight={600}>{blacklistedVehicleCounts?.black_listed_people ?? 0}</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Box className="blacklist-wrapper">
				<Box className="blacklist-header-wrapper">
					<Box className="blacklist-header-actions-wrapper">
						<SecureLoginAccessButton
							variant="outlined"
							color="success"
							startIcon={<AddCircleIcon />}
							sx={{ paddingX: 2 }}
							onClick={handleOpenAddBlacklistVehicleDialog}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Add
						</SecureLoginAccessButton>

						{rowSelectionEnabled ? (
							<SecureLoginAccessButton
								variant="outlined"
								color="primary"
								startIcon={<CancelIcon />}
								sx={{ paddingX: 2 }}
								onClick={handleDisableRowSelection}
								tooltipAction={handleOpenSecureLoginDialog}
							>
								Cancel
							</SecureLoginAccessButton>
						) : (
							<SecureLoginAccessButton
								variant="outlined"
								color="primary"
								startIcon={<CheckBoxIcon />}
								sx={{ paddingX: 2 }}
								onClick={handleEnableRowSelection}
								tooltipAction={handleOpenSecureLoginDialog}
							>
								Select
							</SecureLoginAccessButton>
						)}

						<SecureLoginAccessButton
							variant="outlined"
							color="error"
							startIcon={<DeleteOutlineIcon />}
							sx={{ paddingX: 2 }}
							disabled={selectedRowIds.length <= 0}
							onClick={handleOpenDeleteConfirmationDialog}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Remove
						</SecureLoginAccessButton>
					</Box>

					<Box className="blacklist-header-actions-wrapper">
						<Box component="form" noValidate onSubmit={handleSearchBlacklistVehicle}>
							<TextField
								size="small"
								onChange={handleChangeSearchInput}
								value={searchInput}
								placeholder="Search"
								inputRef={searchInputRef}
								sx={{ width: "200px" }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{filters.number_plate ? (
												<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
													<CloseIcon fontSize="inherit" />
												</IconButton>
											) : null}

											<IconButton type="submit" edge="end">
												<SearchIcon fontSize="medium" />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Box>

						<SecureLoginAccessButton
							variant="outlined"
							color="primary"
							startIcon={<FileUploadIcon />}
							sx={{ paddingX: 3 }}
							onClick={handleOpenUploadDataDialog}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Upload
						</SecureLoginAccessButton>

						<LoadingButton
							variant="contained"
							color="primary"
							startIcon={<DownloadIcon />}
							onClick={handleDownloadButtonClick}
							loading={showDownloadButtonLoader}
							loadingPosition="start"
						>
							Download
						</LoadingButton>
					</Box>
				</Box>

				<Box className="blacklist-table-wrapper">
					<DataGrid
						columns={columnsData}
						rows={getBlacklistRowDetails(getBlacklistedVehiclesResponse?.results ?? [])}
						getRowId={(item) => item.id}
						rowSelectionModel={selectedRowIds}
						checkboxSelection={rowSelectionEnabled}
						onRowSelectionModelChange={handleSelectRows}
						loading={getBlacklistedVehiclesLoading || getBlacklistedVehiclesFetching}
						disableColumnMenu
						disableRowSelectionOnClick
						density="compact"
						rowSpacingType="border"
						columnHeaderHeight={80}
						paginationMode="server"
						pageSizeOptions={[ROWS_PER_PAGE]}
						onPaginationModelChange={handlePageChange}
						rowCount={getBlacklistedVehiclesResponse?.count ?? 0}
						paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
						slots={{ pagination: BlacklistPagination }}
						slotProps={{ footer: { sx: { justifyContent: "center" } } }}
						hideFooter={(getBlacklistedVehiclesResponse?.count ?? 0) <= ROWS_PER_PAGE}
						classes={{
							columnSeparator: "blacklist-table-column-separator",
							columnHeader: "blacklist-table-column-headers",
							cell: "blacklist-table-cell"
						}}
					/>
				</Box>
			</Box>

			<AddBlacklistVehicleDialog open={showAddBlacklistVehicleDialog} onClose={handleCloseAddBlacklistVehicleDialog} />

			{/* <UploadDataDialog
				open={showUploadDataDialog}
				onClose={handleCloseUploadDataDialog}
				onUploadButtonClick={handleUploadBlacklistDetails}
				sampleFileUrl={"/analytics/blacklist_vehicles/download_template"}
				sampleFileName={"BlacklistSample.xlsx"}
				// uploadButtonLoading={uploadBlacklistDataLoading}
			/> */}

			<NewUploadDataDialog
				open={showUploadDataDialog}
				sampleFileUrl="/analytics/blacklist_vehicles/download_template"
				onClose={handleCloseUploadDataDialog}
				onUploadButtonClick={handleUploadBlacklistDetails}
				uploadButtonLoading={uploadDataLoading}
				disableCustomFlatNumber
			/>

			<ConfirmationDialog
				open={showDeleteConfirmationDialog}
				title="Delete Vehicles"
				heading="Are you sure you want to delete selected vehicles?"
				onClose={handleCloseDeleteConfirmationDialog}
				onConfirm={handleDeleteSelectedVehicles}
				color="error"
				confirmButtonLoading={deleteBlacklistVehiclesLoading}
				width="sm"
			/>
		</Box>
	);
};

export default Blacklist;
