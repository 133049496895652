import moment from "moment";
import React, { FC } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IUserActivityDetails } from ".";

interface IUserActivityDetailsDialogProps {
	open: boolean;
	activityDetails: IUserActivityDetails | null;
	onClose: () => void;
}

const UserActivityDetailsDialog: FC<IUserActivityDetailsDialogProps> = (props) => {
	const { open, activityDetails, onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				User Activity Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton onClick={onClose}>
						<CloseIcon sx={{ color: "white" }} />
					</IconButton>
				</Box>
			</DialogTitle>

			{activityDetails ? (
				<DialogContent classes={{ root: "dialog-content-root" }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Box className="activity-detail-item">
								<Typography variant="body2" color="#00000080" fontWeight={500}>
									User Name
								</Typography>

								<Typography>{activityDetails.user_name}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} md={6}>
							<Box className="activity-detail-item">
								<Typography variant="body2" color="#00000080" fontWeight={500}>
									Timestamp
								</Typography>

								<Typography>{moment(activityDetails.timestamp).format("DD/MM/YYYY hh:mm A")}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box className="activity-detail-item">
								<Typography variant="body2" color="#00000080" fontWeight={500}>
									Activity
								</Typography>

								<Typography>{activityDetails.activity}</Typography>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
			) : null}
		</Dialog>
	);
};

export default UserActivityDetailsDialog;
