import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Typography
} from "@mui/material";
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NumbersIcon from "@mui/icons-material/Numbers";
import SquareIcon from "@mui/icons-material/Square";
import DescriptionIcon from "@mui/icons-material/Description";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import RememberMeIcon from "@mui/icons-material/RememberMe";

import "./eventLogs.css";
import { ICoordinate, IDimensions, IEventLogRowDetails, IGetTurnaroundLogsRequestData } from "../../types";
import { ReactComponent as NoImageIcon } from "../../assets/icons/image-not-found-icon.svg";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { convertToTitleCase, downloadImageFromUrl, getFormattedDuration } from "../../utils/commonUtils";
import { ROWS_PER_PAGE, VEHICLE_TYPE_E } from "../../utils/constants";
import { ReactComponent as BikeIcon } from "../../assets/icons/bike-icon.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/car-icon.svg";
import { ReactComponent as BikeEntryIcon } from "../../assets/icons/dash-bike-entry.svg";
import { ReactComponent as BikeExitIcon } from "../../assets/icons/dash-bike-exit.svg";
import { ReactComponent as CarEntryIcon } from "../../assets/icons/dash-car-entry.svg";
import { ReactComponent as CarExitIcon } from "../../assets/icons/dash-car-exit.svg";
import { useGetTurnaroundLogsQuery } from "../../redux/reducers/dashboard.reducer";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";

interface ILogDetailsDialogProps {
	open: boolean;
	eventLogDetails: IEventLogRowDetails | null;
	index: number;
	eventsListLength: number;
	loading?: boolean;
	onClose: () => void;
	onChangeToPreviousEvent: () => void;
	onChangeToNextEvent: () => void;
}

enum LOG_DETAILS_TAB_E {
	IMAGE,
	HISTORY
}

type LogDetailsTabRendererType = {
	[key in LOG_DETAILS_TAB_E]: ReactNode;
};

interface ITurnaroundLogsRowDetails {
	id: number;
	entry_timestamp: string;
	exit_timestamp: string;
	time_spent: string;
}

const ImageDimensions: IDimensions = {
	width: 1290,
	height: 720
};

const BBOX_STROKE_COLOR = "#05ff05";
const BBOX_STROKE_WIDTH = 1.25;

const FrameImage: FC<{ src: string; bbox: [number, number, number, number][] }> = (props) => {
	const { src, bbox } = props;

	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [showError, setShowError] = useState<boolean>(false);
	const [canvasDimensions, setCanvasDimensions] = useState<IDimensions>({ height: 0, width: 0 });

	const getScaledCoordinate = useCallback(
		(coordinate: ICoordinate): ICoordinate => {
			const { height: imageHeight, width: imageWidth } = ImageDimensions;
			const { height: canvasHeight, width: canvasWidth } = canvasDimensions;

			return {
				x: (canvasWidth * coordinate.x) / imageWidth,
				y: (canvasHeight * coordinate.y) / imageHeight
			};
		},
		[canvasDimensions]
	);

	useEffect(() => {
		if (src && canvasRef.current && canvasRef.current.offsetWidth) {
			setCanvasDimensions((currentDimensions) => ({
				...currentDimensions,
				width: canvasRef.current?.offsetWidth ?? 0
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src, canvasRef.current]);

	useEffect(() => {
		if (canvasDimensions.width) {
			const canvasHeight = (canvasDimensions.width * ImageDimensions.height) / ImageDimensions.width;

			setCanvasDimensions((currentDimensions) => ({
				...currentDimensions,
				height: canvasHeight
			}));
		}
	}, [canvasDimensions.width, src]);

	useEffect(() => {
		if (canvasRef.current && src) {
			// eslint-disable-next-line no-self-assign
			canvasRef.current.width = canvasRef.current.width;

			const canvasContext = canvasRef.current.getContext("2d");
			if (!canvasContext) return;

			canvasContext.strokeStyle = BBOX_STROKE_COLOR;
			canvasContext.lineWidth = BBOX_STROKE_WIDTH;

			for (const bboxItem of bbox) {
				const startCoordinate = getScaledCoordinate({ x: bboxItem[0], y: bboxItem[1] });
				const bboxDimensions = getScaledCoordinate({ x: bboxItem[2], y: bboxItem[3] });

				canvasContext.strokeRect(startCoordinate.x, startCoordinate.y, bboxDimensions.x, bboxDimensions.y);
			}
		}
	}, [bbox, getScaledCoordinate, src]);

	if (showError || !src) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%"
				}}
			>
				<NoImageIcon width={100} fill="#d5d5d5" stroke="#d5d5d5" />
				<Typography variant="h6" color="#999999">
					Image not available
				</Typography>
			</Box>
		);
	}

	return (
		<canvas
			ref={canvasRef}
			width={canvasDimensions.width}
			height={canvasDimensions.height}
			style={{
				backgroundImage: `url(${src})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: "100%",
				backgroundRepeat: "no-repeat"
			}}
			onError={() => setShowError(true)}
		/>
	);
};

const LogDetailsDialog: FC<ILogDetailsDialogProps> = (props) => {
	const { open, eventLogDetails, loading, onClose, onChangeToNextEvent, onChangeToPreviousEvent } = props;

	const [showFullscreenImage, setShowFullscreenImage] = useState<boolean>(false);
	const [selectedTab, setSelectedTab] = useState<LOG_DETAILS_TAB_E>(LOG_DETAILS_TAB_E.IMAGE);
	const [filters, setFilters] = useState<IGetTurnaroundLogsRequestData>({
		number_plate: "",
		page: 1
	});

	// APIS
	// GET TURNAROUND LOGS
	const {
		data: getTurnaroundLogsResponse,
		isLoading: getTurnaroundLogsLoading,
		isFetching: getTurnaroundLogsFetching
	} = useGetTurnaroundLogsQuery(filters, { skip: !filters.number_plate });

	const LatestEventImage = useMemo<string>(() => {
		let image = "";

		if (getTurnaroundLogsResponse && getTurnaroundLogsResponse.results.length > 0) {
			const latestEvent = getTurnaroundLogsResponse.results[0];

			if (latestEvent.exit_event && latestEvent.exit_event.frame_img_uri) {
				image = latestEvent.exit_event.frame_img_uri;
			} else if (latestEvent.entry_event && latestEvent.entry_event.frame_img_uri) {
				image = latestEvent.entry_event.frame_img_uri;
			}
		}

		return image;
	}, [getTurnaroundLogsResponse]);

	const TurnaroundLogsRowsData = useMemo<ITurnaroundLogsRowDetails[]>(() => {
		const rows: ITurnaroundLogsRowDetails[] = [];

		if (getTurnaroundLogsResponse) {
			for (const logItem of getTurnaroundLogsResponse.results) {
				rows.push({
					id: logItem.id,
					entry_timestamp: logItem.entry_event
						? moment(logItem.entry_event.timestamp).format("YYYY-MM-DD HH:mm:ss")
						: "--",
					exit_timestamp: logItem.exit_event
						? moment(logItem.exit_event.timestamp).format("YYYY-MM-DD HH:mm:ss")
						: "--",
					time_spent: logItem.stay_duration ? getFormattedDuration(logItem.stay_duration) : "--"
				});
			}
		}

		return rows;
	}, [getTurnaroundLogsResponse]);

	function handleShowFullscreenImage() {
		setShowFullscreenImage(true);
	}

	function handleHideFullscreenImage() {
		setShowFullscreenImage(false);
	}

	function handleDownloadImage() {
		if (eventLogDetails && eventLogDetails.unique_id && eventLogDetails.frame_image) {
			downloadImageFromUrl(eventLogDetails.frame_image, `Evidence-${eventLogDetails.unique_id}.png`);
		}
	}

	function handleChangeSelectedTab(updatedValue: LOG_DETAILS_TAB_E) {
		setSelectedTab(updatedValue);
	}

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: event.page + 1
		}));
	}

	const keyPressEventListener = useCallback(
		(event: KeyboardEvent) => {
			if (open) {
				if (event.key === "ArrowLeft") {
					event.preventDefault();
					onChangeToPreviousEvent();
				} else if (event.key === "ArrowRight") {
					event.preventDefault();
					onChangeToNextEvent();
				} else if (event.key === "Escape") {
					if (showFullscreenImage) {
						handleHideFullscreenImage();
					}
				}
			}
		},
		[onChangeToNextEvent, onChangeToPreviousEvent, open, showFullscreenImage]
	);

	useEffect(() => {
		document.addEventListener("keydown", keyPressEventListener, false);
		return () => {
			document.removeEventListener("keydown", keyPressEventListener, false);
		};
	}, [keyPressEventListener]);

	useEffect(() => {
		if (!open) {
			setSelectedTab(LOG_DETAILS_TAB_E.IMAGE);
		}
	}, [open]);

	useEffect(() => {
		if (eventLogDetails) {
			setFilters({
				number_plate: eventLogDetails.vehicle_number,
				page: 1
			});
		}
	}, [eventLogDetails]);

	const columnsData: GridColDef[] = [
		{
			field: "entry_timestamp",
			headerName: "Entry",
			flex: 1,
			minWidth: 120,
			sortable: false,
			align: "center",
			headerAlign: "center"
		},
		{
			field: "exit_timestamp",
			headerName: "Exit",
			flex: 1,
			minWidth: 120,
			sortable: false,
			align: "center",
			headerAlign: "center"
		},
		{
			field: "time_spent",
			headerName: "Time Spent",
			flex: 1,
			minWidth: 100,
			sortable: false,
			align: "center",
			headerAlign: "center"
		}
	];

	const TabRenderer: LogDetailsTabRendererType = {
		[LOG_DETAILS_TAB_E.IMAGE]: eventLogDetails ? (
			<>
				<Grid item xs={12} md={4}>
					<Box className="log-detail-wrapper">
						<Box className="log-detail-icon">
							<PersonIcon color="primary" />
						</Box>

						<Box className="log-detail">
							<Typography variant="subtitle2" color="#00000080">
								Name
							</Typography>

							<Typography variant="subtitle2" fontWeight={600}>
								{eventLogDetails.name}
							</Typography>
						</Box>
					</Box>
				</Grid>

				{eventLogDetails.type === "visitor" && eventLogDetails.contact_number ? (
					<Grid item xs={12} md={4}>
						<Box className="log-detail-wrapper">
							<Box className="log-detail-icon">
								<NumbersIcon color="primary" />
							</Box>

							<Box className="log-detail">
								<Typography variant="subtitle2" color="#00000080">
									Contact Number
								</Typography>

								<Typography variant="subtitle2" fontWeight={600}>
									{eventLogDetails.contact_number}
								</Typography>
							</Box>
						</Box>
					</Grid>
				) : null}

				{eventLogDetails.camera_name ? (
					<Grid item xs={12} md={4}>
						<Box className="log-detail-wrapper">
							<Box className="log-detail-icon">
								<LocationOnIcon color="primary" />
							</Box>

							<Box className="log-detail">
								<Typography variant="subtitle2" color="#00000080">
									Location
								</Typography>

								<Typography variant="subtitle2" fontWeight={600}>
									{eventLogDetails.camera_name}
								</Typography>
							</Box>
						</Box>
					</Grid>
				) : null}

				<Grid item xs={12} md={4}>
					<Box className="log-detail-wrapper">
						<Box className="log-detail-icon">
							<NumbersIcon color="primary" />
						</Box>

						<Box className="log-detail">
							<Typography variant="subtitle2" color="#00000080">
								Plate Number
							</Typography>

							<Typography variant="subtitle2" fontWeight={600}>
								{eventLogDetails.vehicle_number}
							</Typography>
						</Box>
					</Box>
				</Grid>

				{eventLogDetails.flat_number ? (
					<Grid item xs={12} md={4}>
						<Box className="log-detail-wrapper">
							<Box className="log-detail-icon">
								<SquareIcon color="primary" />
							</Box>

							<Box className="log-detail">
								<Typography variant="subtitle2" color="#00000080">
									Flat Number
								</Typography>

								<Typography variant="subtitle2" fontWeight={600}>
									{eventLogDetails.flat_number}
								</Typography>
							</Box>
						</Box>
					</Grid>
				) : null}

				{eventLogDetails.type === "visitor" && eventLogDetails.address ? (
					<Grid item xs={12} md={4}>
						<Box className="log-detail-wrapper">
							<Box className="log-detail-icon">
								<LocationOnIcon color="primary" />
							</Box>

							<Box className="log-detail">
								<Typography variant="subtitle2" color="#00000080">
									Address
								</Typography>

								<Typography variant="subtitle2" fontWeight={600}>
									{eventLogDetails.address}
								</Typography>
							</Box>
						</Box>
					</Grid>
				) : null}

				{eventLogDetails.custom_field_data && Object.keys(eventLogDetails.custom_field_data).length > 0
					? Object.entries(eventLogDetails.custom_field_data).map(([key, value]) =>
							value ? (
								<Grid item xs={12} md={4}>
									<Box className="log-detail-wrapper">
										<Box className="log-detail-icon">
											<DescriptionIcon color="primary" />
										</Box>

										<Box className="log-detail">
											<Typography variant="subtitle2" color="#00000080">
												{key}
											</Typography>

											<Typography variant="subtitle2" fontWeight={600}>
												{value}
											</Typography>
										</Box>
									</Box>
								</Grid>
							) : null
					  )
					: null}

				<Grid item xs={12} md={4}>
					<Box className="log-detail-wrapper">
						<Box className="log-detail-icon">
							<AccessTimeFilledIcon color="primary" />
						</Box>

						<Box className="log-detail">
							<Typography variant="subtitle2" color="#00000080">
								Timestamp
							</Typography>

							<Typography variant="subtitle2" fontWeight={600}>
								{moment(eventLogDetails.timestamp).format("DD MMM YYYY, hh:mm A")}
							</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<Box className="log-detail-wrapper">
						<Box className="log-detail-icon">
							<RememberMeIcon color="primary" />
						</Box>

						<Box className="log-detail">
							<Typography variant="subtitle2" color="#00000080">
								Type
							</Typography>

							<Typography variant="subtitle2" fontWeight={600}>
								{convertToTitleCase(eventLogDetails.type)}
							</Typography>
						</Box>
					</Box>
				</Grid>

				{/* selectedTab === LOG_DETAILS_TAB_E.IMAGE
												? eventLogDetails.frame_image
												: selectedTab === LOG_DETAILS_TAB_E.HISTORY
												? LatestEventImage
												: "" */}

				<Grid item xs={12}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: "1rem" }}>
						<LoadingButton
							variant="contained"
							color="primary"
							startIcon={<DownloadIcon />}
							sx={{ paddingX: 3 }}
							onClick={handleDownloadImage}
							disabled={
								(selectedTab === LOG_DETAILS_TAB_E.IMAGE && !eventLogDetails.frame_image) ||
								(selectedTab === LOG_DETAILS_TAB_E.HISTORY && !LatestEventImage) ||
								false
							}
						>
							Download
						</LoadingButton>
					</Box>
				</Grid>
			</>
		) : null,
		[LOG_DETAILS_TAB_E.HISTORY]:
			eventLogDetails && getTurnaroundLogsResponse ? (
				<>
					<Grid item xs={12}>
						<Box className="log-history-vehicle-number-wrapper">
							<Box className="log-history-auth-type" />

							<Box className="log-history-vehicle-type-wrapper">
								{eventLogDetails.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER ? (
									<CarIcon width={24} fill="var(--color-primary-main)" />
								) : (
									<BikeIcon width={24} fill="var(--color-primary-main" />
								)}
							</Box>

							<Box className="log-history-vehicle-number">
								<Typography fontWeight={500}>{eventLogDetails.vehicle_number}</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box className="log-history-count-wrapper entry">
							<Box className="log-history-count-type">
								<Box className="log-history-count-type-icon">
									{eventLogDetails.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER ? (
										<CarEntryIcon width={24} />
									) : (
										<BikeEntryIcon width={24} />
									)}
								</Box>

								<Typography variant="body1" color="#00000080">
									Entry
								</Typography>
							</Box>

							<Box className="log-history-count">
								<Typography fontWeight={500}>{getTurnaroundLogsResponse.entry_count}</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box className="log-history-count-wrapper exit">
							<Box className="log-history-count-type">
								<Box className="log-history-count-type-icon">
									{eventLogDetails.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER ? (
										<CarExitIcon width={24} />
									) : (
										<BikeExitIcon width={24} />
									)}
								</Box>

								<Typography variant="body1" color="#00000080">
									Exit
								</Typography>
							</Box>

							<Box className="log-history-count">
								<Typography fontWeight={500}>{getTurnaroundLogsResponse.exit_count}</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<DataGrid
							columns={columnsData}
							rows={TurnaroundLogsRowsData}
							loading={getTurnaroundLogsLoading || getTurnaroundLogsFetching}
							disableColumnMenu
							disableRowSelectionOnClick
							density="compact"
							rowSpacingType="border"
							columnHeaderHeight={80}
							paginationMode="server"
							pageSizeOptions={[ROWS_PER_PAGE]}
							onPaginationModelChange={handlePageChange}
							rowCount={getTurnaroundLogsResponse.count}
							paginationModel={{ page: filters.page ? filters.page - 1 : 0, pageSize: ROWS_PER_PAGE }}
							hideFooter={(getTurnaroundLogsResponse?.count ?? 0) <= ROWS_PER_PAGE}
							classes={{
								columnSeparator: "log-history-table-column-separator",
								columnHeader: "log-history-table-column-header",
								virtualScroller: "log-history-table-virtual-scroller"
							}}
						/>
					</Grid>
				</>
			) : null
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md" disableEscapeKeyDown={showFullscreenImage}>
			<DialogTitle textAlign="center" color="white" classes={{ root: "dialog-title-root primary" }}>
				Log Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				{eventLogDetails ? (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box className="log-details-tabs-wrapper">
								<Box
									className={`log-details-tab-button ${selectedTab === LOG_DETAILS_TAB_E.IMAGE ? "active" : ""}`}
									onClick={() => handleChangeSelectedTab(LOG_DETAILS_TAB_E.IMAGE)}
								>
									<Typography variant="subtitle2" fontWeight={500}>
										Image
									</Typography>
								</Box>

								<Divider flexItem orientation="vertical" sx={{ borderRightWidth: "2px", borderColor: "#01579B" }} />

								<Box
									className={`log-details-tab-button ${selectedTab === LOG_DETAILS_TAB_E.HISTORY ? "active" : ""}`}
									onClick={() => handleChangeSelectedTab(LOG_DETAILS_TAB_E.HISTORY)}
								>
									<Typography variant="subtitle2" fontWeight={500}>
										History
									</Typography>
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box className="log-details-dialog-header">
								<Box className="log-change-arrow" onClick={onChangeToPreviousEvent}>
									<ChevronLeftIcon color="primary" sx={{ fontSize: "48px" }} />
								</Box>

								<Box className="log-evidence-image-wrapper">
									<FrameImage
										src={
											selectedTab === LOG_DETAILS_TAB_E.IMAGE
												? eventLogDetails.frame_image
												: selectedTab === LOG_DETAILS_TAB_E.HISTORY
												? LatestEventImage
												: ""
										}
										bbox={eventLogDetails.bbox}
									/>

									{(selectedTab === LOG_DETAILS_TAB_E.IMAGE && eventLogDetails.frame_image) ||
									(selectedTab === LOG_DETAILS_TAB_E.HISTORY && LatestEventImage) ? (
										<IconButton
											disableRipple
											sx={{ position: "absolute", top: "0", right: "0" }}
											onClick={handleShowFullscreenImage}
										>
											<FullscreenIcon sx={{ color: "white" }} fontSize="large" />
										</IconButton>
									) : null}
								</Box>

								<Box className="log-change-arrow" onClick={onChangeToNextEvent}>
									<ChevronRightIcon color="primary" sx={{ fontSize: "48px" }} />
								</Box>
							</Box>
						</Grid>

						{TabRenderer[selectedTab]}
					</Grid>
				) : loading ? (
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<CircularProgress />
					</Box>
				) : null}
			</DialogContent>

			{showFullscreenImage ? (
				<Box className="fullscreen-evidence-image-section" onClick={handleHideFullscreenImage}>
					<Box className="fullscreen-evidence-image-wrapper" onClick={(event) => event.stopPropagation()}>
						{/* {eventLogDetails ? (
							<img
								src={
									selectedTab === LOG_DETAILS_TAB_E.IMAGE
										? eventLogDetails.frame_image
										: selectedTab === LOG_DETAILS_TAB_E.HISTORY
										? LatestEventImage
										: ""
								}
								alt={eventLogDetails.vehicle_number}
								style={{ height: "100%", width: "100%", objectFit: "contain" }}
							/>
						) : null} */}

						{eventLogDetails ? (
							<FrameImage
								src={
									selectedTab === LOG_DETAILS_TAB_E.IMAGE
										? eventLogDetails.frame_image
										: selectedTab === LOG_DETAILS_TAB_E.HISTORY
										? LatestEventImage
										: ""
								}
								bbox={eventLogDetails.bbox}
							/>
						) : null}
					</Box>

					<IconButton
						disableRipple
						sx={{ position: "absolute", top: "0", right: "0" }}
						onClick={handleHideFullscreenImage}
					>
						<FullscreenExitIcon sx={{ color: "white" }} fontSize="large" />
					</IconButton>
				</Box>
			) : null}
		</Dialog>
	);
};

export default LogDetailsDialog;
